import { RecurrenceInterval } from '@prisma/client';
import dayjs from 'dayjs';

export const addBusinessDays = (date: Date, days: number) =>
  dayjs(date).businessDaysAdd(days);

export const convertRecurrenceIntervalToInt = (
  recurrenceInterval: RecurrenceInterval,
) => {
  return {
    [RecurrenceInterval.never]: 0,
    [RecurrenceInterval.weekly]: 1,
    [RecurrenceInterval.biweekly]: 2,
    [RecurrenceInterval.monthly]: 3,
    [RecurrenceInterval.quarterly]: 4,
    [RecurrenceInterval.yearly]: 5,
  }[recurrenceInterval];
};

export const getDateToSendSms = () => {
  const isBetween5pmAnd12am = dayjs().isBetween(
    dayjs().hour(17).minute(0).second(0),
    dayjs().hour(23).minute(59).second(59),
    'hour',
    '[]',
  );

  if (isBetween5pmAnd12am) {
    return new Date();
  }

  return dayjs().hour(17).minute(0).second(0).toDate();
};
