import { Account, User } from '@prisma/client';
import dayjs from 'dayjs';

export const validateEmail = (email: string) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    String(email).toLowerCase(),
  );

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const internationallyFormatPhoneNumber = (number: string) => {
  const noLetters = number.replace(/[^0-9]/g, '');
  if (noLetters.length == 11 && noLetters[0] == '1') {
    return `+${noLetters}`;
  }

  if (noLetters.length == 10) {
    return `+1${noLetters}`;
  }

  throw new Error(`Invalid phone number ${number}`);
};

// Remove leading +1 from phone number
export const locallyFormatPhoneNumber = (number: string) => {
  if (!number) return number;
  if (!number.length) return number;
  const noLetters = number.replace(/[^0-9]/g, '');
  if (noLetters.length == 11 && noLetters[0] == '1') {
    return noLetters.slice(1);
  }

  if (noLetters.length == 10) {
    return noLetters;
  }

  throw new Error(`Invalid phone number ${number}`);
};

export const formattedFiveDigitZipCode = (zipCode: string) => {
  const noLetters = zipCode.replace(/[^0-9]/g, '');
  return noLetters.slice(0, 5);
};

export const namePosessive = (name: string) => {
  return name.endsWith('s') ? `${name}'` : `${name}'s`;
};

// Add apostrophe to the end of a string
export const nameWithApostrophe = (str?: string) =>
  str ? `${str}${str.endsWith('s') ? "'" : "'s"}` : '';

// A function that normalizes names that are user inputted. It should:
// - Remove any leading or trailing whitespace
// - Uncapitalize all letters if the name is all uppercase
// - Capitalize the first letter of each word
// - Respect names with deliberately mixed case (i.e. McDonald)
// - Respect prefixes (i.e. Dr.) and suffixes (i.e. Jr., Sr., III)
// - Respect hyphenated names (i.e. Mary-Kate)
// - Respect names with apostrophes (i.e. O'Connor)
// - Respect names with periods (i.e. J.R.R. Tolkien)
// - Respect names with spaces (i.e. John Jacob Jingleheimer Schmidt)
export const normalizeName = (name: string): string => {
  const capitalize = (word: string) => {
    // If word contains mixed casing, return it as is
    if (word.match(/[a-z]/) && word.match(/[A-Z]/)) {
      return word;
    }

    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  };

  return name
    .trim()
    .split(/\s+/)
    .map((word) => {
      // If the word is a common prefix or suffix, return it as is
      if (word.match(/^(jr|sr|ii|iii|iv|md|phd|dr)$/i)) {
        return word;
      }

      // If the word is hyphenated, capitalize each word
      if (word.match(/-/)) {
        return word
          .split(/-+/)
          .map((w) => normalizeName(w))
          .join('-');
      }

      // If the word has an apostrophe, capitalize each word
      if (word.match(/'/)) {
        return word
          .split(/'+/)
          .map((w) => normalizeName(w))
          .join("'");
      }

      // If the word has a period, capitalize each word
      if (word.match(/\./)) {
        return word
          .split(/\.+/)
          .map((w) => normalizeName(w))
          .join('.');
      }

      // If the word has a special character or number, return it as is
      if (word.match(/[^a-zA-Z]/)) {
        return word;
      }

      // Return it
      return capitalize(word);
    })
    .join(' ');
};

export const acceptedTwoDigitStateCodes = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const profileLinkUrl = (
  account: Account,
  raiseFrontendBaseUrl: string | undefined,
) => {
  return `${raiseFrontendBaseUrl}/scholars/${account.hashid}`;
};

export const getNameOfAccount = (account: Partial<Account>) => {
  if (!account) return '';

  if (!account.beneficiary_first_name || !account.beneficiary_last_name)
    return 'Expected Child';

  const { beneficiary_first_name, beneficiary_last_name } = account;

  return `${beneficiary_first_name} ${beneficiary_last_name}`;
};

export const getNameOfUser = (user: User) => {
  const { first_name, last_name } = user;

  return `${first_name} ${last_name}`;
};

export const getFormattedBirthOrDueDate = (account: Account) => {
  const { beneficiary_birth_date, beneficiary_estimated_due_date } = account;

  const date = beneficiary_birth_date
    ? beneficiary_birth_date
    : beneficiary_estimated_due_date;

  return dayjs(date).utc().format('MMMM Do, YYYY');
};
