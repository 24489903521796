import { Contributions } from '@prisma/client';

export type CompoundingProjections = {
  principal: number;
  returns: number;
  position: number;
  contributions: number;
  contributionsToDate: number;
  net: number;
  period: number;
};

type GetProjections = (
  contributions: {
    initial?: number;
    weekly?: number;
    biweekly?: number;
    monthly?: number;
    quarterly?: number;
    yearly?: number;
  },
  rate: number,
  years: number,
  removeInitialFromNet?: boolean,
  period?: 'weekly' | 'biweekly' | 'monthly' | 'quarterly' | 'yearly',
) => CompoundingProjections[];

export const getProjections: GetProjections = (
  cs,
  rate,
  years,
  removeInitialFromNet = true,
  period = 'yearly',
) => {
  // Hold the result
  const results: CompoundingProjections[] = [];

  // Define period amounts (pa) within a year
  const pa = {
    never: 1,
    weekly: 52,
    biweekly: 26,
    monthly: 12,
    quarterly: 4,
    yearly: 1,
  };

  // For each period, calculate the projection
  for (let i = 1; i <= years * pa[period]; i++) {
    // Get the last period
    const lastPeriod = results.length > 0 ? results[results.length - 1] : null;

    // Calculate what to contribute this period
    let contributions = 0;

    // Determine how much to contributed based on the period and the amount
    if (i === 1 && cs.initial) {
      contributions += cs.initial;
    }
    Object.entries(cs).forEach(([key, value]) => {
      if (key !== 'initial' && value) {
        const mul = pa[key as keyof typeof pa] / pa[period];
        contributions += value * mul;
      }
    });

    // Get the amount for this period
    // Either the initial amount or the previous week's position + any contributions for the period
    const principal = (lastPeriod?.position || 0) + contributions;

    // Calculate the returns for this period
    const returns = principal * (rate / 100 / pa[period]);

    // Calculate the position for the period
    const position = principal + returns;

    // Calculate the contributions to date
    const contributionsToDate =
      (lastPeriod?.contributionsToDate || 0) + contributions;

    // Calulate the net for the period
    const net =
      position - (removeInitialFromNet && cs.initial ? cs.initial : 0);

    // Push it onto the stack
    results.push({
      principal,
      returns,
      position,
      contributions,
      contributionsToDate,
      net,
      period: i,
    });
  }

  // Return the array at the end
  return results;
};

export const calculateContributionAmountWithFeesIncluded = (
  contribution: Contributions,
) => {
  const { raw_amount, platform_fee, processing_fee } = contribution;

  const totalAmount = +raw_amount + +platform_fee + +processing_fee;

  return totalAmount;
};
