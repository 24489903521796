export const isSSR: boolean = !(
  typeof window !== 'undefined' && window.document?.createElement
);

export const scrollToElem = (id: string, navbarId: string) => {
  if (!isSSR) {
    const navbarHeight = document.getElementById(navbarId)?.offsetHeight || 0;
    const topbarClearance = navbarHeight;

    const elem = document.getElementById(id);
    const elemTop = elem
      ? elem.getBoundingClientRect().top + window.scrollY
      : 0;

    window.scrollTo({
      top: elemTop - topbarClearance,
      behavior: 'smooth',
    });
  }
};

export const scrollToTop = () => {
  if (!isSSR) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
};

// Copy text to clipboard
export const copyContent = async (
  text: string,
  callback?: () => void,
  errorCallback?: (error: unknown) => void,
) => {
  try {
    await navigator.clipboard.writeText(text);

    if (callback) callback();
  } catch (err) {
    console.error('Failed to copy: ', err);

    if (errorCallback) errorCallback(err);
  }
};

// Determine scholar URL
export const getScholarProfileUrl = (hashid: string) => {
  const origin =
    typeof window !== 'undefined' && window.location.origin
      ? window.location.origin
      : '';
  const relativeProfileUrl = `/scholars/${hashid}`;
  const profileUrl = `${origin}${relativeProfileUrl}`;

  return {
    relativeURL: relativeProfileUrl,
    absoluteURL: profileUrl,
  };
};
