export const abbreviateUSD = (num: number, digits: number = 2) => {
  const si = [
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'B' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' },
  ];

  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return `$${
        (num / si[i].value)
          .toFixed(digits)
          .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
      }`;
    }
  }

  return `$${num.toString()}`;
};

// Formats 12345 as $12,345.00
export const formatUSD = (value: number | string, decimalPlaces?: number) => {
  const decimals = decimalPlaces == 0 ? 0 : (decimalPlaces ?? 2);

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(+value);
};
