import { usePostHog } from 'posthog-js/react';

import { useIntercom } from './Intercom';

type IdentifyData = {
  id: number | string;
  email: string;
  hashid: string;
  first_name: string;
  last_name: string;
  created_at?: Date | number | string;
  birth_date?: Date | number | string;
  phone?: string;
  picture?: string;
};

const toDateNumber = (
  date: Date | number | string | undefined,
  seconds = true,
) => {
  // If the date is undefined, return undefined
  if (!date) return undefined;

  // If the date is a Date object, return the time in seconds
  if (date instanceof Date) return date.getTime() / (seconds ? 1000 : 1);

  // If the date is a string, return the time in seconds
  if (typeof date === 'string') {
    return new Date(date).getTime() / (seconds ? 1000 : 1);
  }

  // If the date is a number, return the number
  return date;
};

const useEventLogger = () => {
  const posthog = usePostHog();
  const intercom = useIntercom();

  return {
    identify: async (data: IdentifyData) => {
      // Get the Intercom HMAC
      const hmacRequest = await fetch(`/api/intercom-hmac?id=${data.id}`);
      const hmacJson = await hmacRequest.json();
      const hmac = hmacJson.data.hmac;

      // If we have a HMAC...
      if (hmac) {
        // Identify the user on PostHog
        posthog.identify(data.id.toString(), {
          id: data.id,
          hashid: data.hashid,
          email: data.email,
          first_name: data.first_name || undefined,
          last_name: data.last_name || undefined,
          birth_date: toDateNumber(data.birth_date, false),
          phone: data.phone || undefined,
          picture: data.picture || undefined,
          created_at: toDateNumber(data.created_at, false),
        });

        // Update the user on Intercom
        intercom.update({
          email: data.email,
          user_id: data.id.toString(),
          created_at: toDateNumber(data.created_at),
          signed_up_at: toDateNumber(data.created_at),
          name: `${data.first_name || ''} ${data.last_name || ''}`,
          phone: data.phone || undefined,
          avatar: data.picture
            ? {
                type: 'avatar',
                image_url: data.picture,
              }
            : undefined,
          user_hash: hmac,
        });
      }
    },
    track: (name: string, properties?: Record<string, any>) => {
      if (process.env.NODE_ENV === 'development') {
        console.log('Event', name, properties);
      }

      const dateNumberedProperties = {
        ...(properties || {}),
        ...Object.entries(properties || {}).reduce(
          (acc, [key, value]) => {
            if (value instanceof Date) {
              acc[key as keyof typeof acc] = toDateNumber(value, false);
            }

            return acc;
          },
          {} as Record<string, any>,
        ),
      };

      // PostHog requires all date properties to be numbered
      posthog.capture(name, dateNumberedProperties);

      // Intercom requires all date properties to be Date objects
      intercom.trackEvent(name, properties);
    },
  };
};

export default useEventLogger;
